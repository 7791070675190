.parallelogram-wrapper {
  @apply flex-none h-[273px] m-auto relative w-[342px];
  @apply md:m-auto md:ml-auto;
}

.parallelogram {
  @apply h-full overflow-hidden relative w-full !important;
}

.parallelogram-copy {
  @apply min-w-[300px] md:mr-12 mt-8 md:mt-0;
}

.parallelogram-slant {
  @apply bg-danger-500 bottom-0 h-[65%] absolute transform skew-x-[-12deg] w-[6px];
}

.parallelogram::after,
.parallelogram::before {
  @apply bg-light bottom-0 content-[""] block h-full absolute w-[17%];
}

.parallelogram::before {
  @apply left-0 transform skew-x-[-12deg] -translate-x-1/2;
}

.parallelogram::after {
  @apply right-0 mb-[-1px] transform skew-x-[-12deg] translate-x-1/2;
}

.parallelogram-img {
  @apply border-0 h-full object-cover object-bottom w-full;
  margin-top: 0;
  margin-bottom: 0;
}
